<template>
  <AVInstantRunoffSummary
    :rounds="rounds"
    :sorted-result="sortedResult"
    :seat-number="seatNumber"
    :quota="result.quota"
    :total-votes="result.totalVotes"
    :blank-votes="result.exclusiveOptionCounts.blank"
    :votes-not-included="votesNotIncluded"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import type { ResultRound, ConferenceOption, PropType, Result, ConferenceContest, ResultMethod } from "@/types";

export default defineComponent({
  name: "InstantRunoffSummary",
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    resultMethod: {
      type: String as PropType<ResultMethod>,
      required: true,
    },
    seatNumber: {
      type: Number,
      default: null,
    },
    votesNotIncluded: {
      type: Number,
      default: null,
    },
  },
  computed: {
    rounds() {
      return this.result.rounds;
    },
    options() {
      return this.flattenOptions(this.contest.options);
    },
    sortedOptions() {
      const sortedOptions = [];
      let elected = {};

      this.rounds.map((round: ResultRound) => {
        this.options.map((option: ConferenceOption) => {
          if (round.eliminated === option.reference) sortedOptions.unshift(option);
          if (round.elected === option.reference) elected = option;
        });
      });

      // Winner on top no matter in which round was elected
      sortedOptions.unshift(elected);

      return sortedOptions;
    },
    sortedResult() {
      return this.sortedOptions.map((option: ConferenceOption) => {
        return {
          reference: option.reference,
          title: option.title,
        }
      });
    },
  },
  methods: {
    flattenOptions,
  },
});
</script>
